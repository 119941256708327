import React from 'react';
import CategoryPage from './CategoryPage';
import Header from '../Common/Header';

function Layout_diy(props) {
    return (
        <div>
            <Header />
            <CategoryPage {...props} />
        </div>
    )
}

export default Layout_diy