import axios from "axios";

export function login(body) {console.log( 'login in')

  
  const fetchData = axios.post('/api/user/login', body)
    .then(response => response.data)

  //user 정보를 가져오는데 걸리는 시간때문에 *** 비동기 패턴 *** 사용함
  const async = async () => {
    const userData = await fetchData;
    return { type: "LOGIN", user: userData }
  }
  
  return async();

  //기존 코드
  // const result = axios.post('/api/user/login', body)
  //     .then(response => response.data)

  //실행결과를 reducer에 리턴한다.
  // return {
  //   type: "LOGIN",
  //   user: result
  // }
      
}

export function loginKakao(url) {console.log( 'loginKakao in')

    const fetchData = axios.get(url)
    .then(response => response.data)

    //user 정보를 가져오는데 걸리는 시간때문에 *** 비동기 패턴 *** 사용함
    const async = async () => {console.log( '@@@ async() in')
    const userData = await fetchData;
    return { type: "LOGIN", user: userData }
    }

    return async();
  
}

export function loginNaver(token) {console.log( '### user_action loginNaver in')

    const url = '/api/user/loginNaver'
    const body = { token: token };

    const fetchData = axios.post(url, body)
    .then(response => response.data)

    //user 정보를 가져오는데 걸리는 시간때문에 *** 비동기 패턴 *** 사용함
    const async = async () => {console.log( '@@@ async() in')
    const userData = await fetchData;
    return { type: "LOGIN", user: userData }
    }

    return async();
  
}
  
