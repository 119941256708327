import React, { useState } from 'react'
import Header from '../Common/Header'
import { useSelector } from 'react-redux';

function CartPage(props) {

    const Server_uri = process.env.REACT_APP_SERVER_URI;
    const productList = useSelector(state => state.cart.productList);
    const [Total, setTotal] = useState(0)

    // return에 <div>를 써서 구현한 코드
    // function itemList() {console.log(productList);
    //   return (

    //     <div>
    //     {productList.map((product, index) => {

    //       return (
    //         <div>
    //         {items(product)}
    //         </div>
    //       )
    //       })
    //     }
    //     </div>
    //   )
    // }

    // 첫번째 return에 <div>를 쓰지않고 구현한 코드
    // function itemList() {
      
    //   const code = productList.map((product, index) => {
    //       return (
    //         <div>
    //         {items(product)}
    //         </div>
    //       )
    //   })

    //   return code
    // }

    const goBuy = () => {
      props.history.push('/login');
    }

    const keepShopping = () => {
      props.history.goBack();
    }

    function calcTotal() {
      let newTotal = Total;
      for(var i=0; i<productList.length; i++) {
        for(var j=0; j<productList[i].option.length; j++) {
          newTotal = newTotal + Number(productList[i].option[j][1]);
        }
      }
      // setTotal(newTotal);
      return newTotal
    }

    // 모든 return에 <div>를 쓰지않고 구현한 코드
    function itemList() {
      
      const code = productList.map((product, index) => {

          return (
            items(product)
          )
      })
      return code
    }

    function items(product) {
      const code = [];
      

      for(var i=0; i<product.option.length; i++) {
        
        code.push(
        <div className="cart-item">
            <div className="info">
              <div className="image">
                <img src={`${Server_uri}/${product.img}`}/>
              </div>
              <div className="name">
                <span>{product.maker}</span>
                <h4>{product.name}</h4>
                <p>{Number(product.option[i][1]).toLocaleString('ko-KR')}<small>원</small></p>
                <h5>옵션 : {product.option[i][0]}</h5>
              </div>
            </div>
            <div className="qty">
              <div className="qty-control">
                <div className="minus"><div><i className="xi-minus-min"></i></div></div>
                <div className="number"><div>1</div></div>
                <div className="plus"><div><i className="xi-plus-min"></i></div></div>
              </div>
            </div>
            <div className="price"><div><p>{Number(product.option[i][1]).toLocaleString('ko-KR')}<small>원</small></p></div></div>
            <div className="shipping"><div><p>무료배송</p></div></div>
          </div>);
      }
      // setTotal(newTotal);
      return code;
    }

  
    return (
        <div>
          <Header />
          <div className="cart-band">
            <div className="cart-sign">
              <p className="title">SHOPPING CART</p>
              <div className="navbar">
                <p className="boldText">쇼핑카트</p>
                <i className="xi-angle-right-thin"></i>
                <p className="subText">주문</p>
                <i className="xi-angle-right-thin"></i>
                <p className="subText">주문확인</p>
              </div>
            </div>
            <div className="cart-outer">
              <div className="cart-nav">
                <p className="nv1">상품정보</p>
                <p className="nv2">수량</p>
                <p className="nv3">주문금액</p>
                <p className="nv4">배송비</p>
              </div>

              {itemList()}

            </div>
            <div className="sumMenu">
              <div className="cart-nav">
                <p className="orderSum">주문금액</p>
                <p className="orderShipping">배송비</p>
                <p className="paySum">결제금액</p>
              </div>
              <div className="calcSum">
                <div className="orderSumNum">
                  <p className="sub-title">주문금액</p>
                  {/* <h1>132,000<small>원</small></h1> */}
                  <h1>{calcTotal().toLocaleString('ko-KR')}<small>원</small></h1>
                </div>
                <div className="plusIcon">
                  <i className="xi-plus"></i>
                </div>
                <div className="orderShippingNum">
                  <p className="sub-title">배송비</p>
                  <h1>0<small>원</small></h1>
                </div>
                <div className="equalIcon">
                <i className="xi-drag-handle"></i>
                </div>
                <div className="paySumNum">
                  <p className="sub-title">결제금액</p>
                  {/* <h1>132,000<small>원</small></h1> */}
                  <h1>{calcTotal().toLocaleString('ko-KR')}<small>원</small></h1>
                </div>
              </div>

              <div className="buttons">
                <div className="back" onClick={keepShopping} >계속 쇼핑하기</div>
                <div className="order" onClick={goBuy}>주문하기</div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default CartPage
