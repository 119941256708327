// 카카오 로그인 리다이렉트될 화면


import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import{ loginKakao } from '../../_actions/user_action'
import axios from "axios";
// import { actionCreators as userActions } from "../redux/modules/user";
// import Spinner from "./Spinner";

const KakaoLoginHandler = (props) => {
//   const dispatch = useDispatch();

  // 인가코드
  const code = new URL(window.location.href).searchParams.get("code");
  console.log('code 1st : ', code);
  const dispatch = useDispatch();

  // 인가코드 서버로 전송
  useEffect(async() => {
    let url = '/api/user/loginKakao?code=' + code;
    
    // ### old code with axios
    // await axios({
    //   method: 'GET',
    //   url: url
    // })
    // .then(response => {console.log(response);
    //   if(response.data.login){
    //     console.log('/api/user/loginKakao response 성공!!');
    //     console.log(response.data);
    //     setData(response.data);
    //     props.history.replace('/');
    //   }else{
    //     setData({login_result : false});
    //     props.history.push('/');
    //   }
    // })

    // ### new code with dispatch
    await dispatch(loginKakao(url)).then(response => {console.log(response);
      if(response.user.login){
        console.log('/api/user/loginKakao response 성공!!');
        console.log(response.user);
        props.history.push('/');
      }else{
        props.history.push('/');
      }
    })


  }, [])




//   React.useEffect(async () => {
//     await dispatch(userActions.kakaoLogin(code));
//   }, []);

//   return <Spinner />;
  return <div>
  </div>
};

export default KakaoLoginHandler;