import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import{ intocart } from '../../_actions/cart_action';
import{ login } from '../../_actions/user_action';
import dotenv from 'dotenv';
import Review from './Review';

dotenv.config();

function DetailPage(props) {

    const contentId = props.match.params.contentId;
    const variable = { contentId: contentId };
    const [Contents, setContents] = useState([]);
    const [ImgList, setImgList] = useState([]);
    const [Choices, setChoices] = useState([]);
    const [ChoiceList, setChoiceList] = useState([]);
    const [Total, setTotal] = useState(0);
    const [Good, setGood] = useState('');
    const [Video, setVideo] = useState([]);
    const Server_uri = process.env.REACT_APP_SERVER_URI;
    const [SelectA, setSelectA] = useState(['','']);
    const [SelectB, setSelectB] = useState(['','']);
    const history = useHistory();
    const dispatch = useDispatch();
    const [Tab, setTab] = useState(1);

    useEffect(() => {
        axios.post('/api/content/getContentDetail', variable)
        .then(response => {
            if(response.data.success) {
                // console.log('@@@@@@@@@@@@@ success! @@@@@@@@@@@');
                // console.log(response.data.contentDetail);
                // console.log('@@@@@ good : ',response.data.contentDetail.good);
                setContents(response.data.contentDetail);
                setVideo(response.data.contentDetail.video);
                setImgList(response.data.contentDetail.img_detail);
                setGood(response.data.contentDetail.good);
            } else {
                alert('콘텐츠를 가져오는데 실패했습니다.')
            }
        })
    }, [])


    //상세이미지 코드 생성
    const imgList = ImgList.map((img, index) => (
      <div key={index}>
      <img src={`${Server_uri}/${img}`} alt=''/>
      </div>
    ));
    
    //상세정보 탭
    function Description() {
      return (
        <>
          <VideoSection />
          <div className="detail-image">
              {imgList}
          </div>
        </>
      )
    }

    //리뷰 탭
    // function Review() {
    //   return (
    //     <>
    //       Review Tab
    //     </>
    //   )
    // }

    //포토리뷰 탭
    function Photo() {
      return (
        <>
          <br />
          <br />
          <br />
        </>
      )
    }

    //3개의 탭
    function ThreeTabs() {
      
      if(Tab == 2) {
        return (
          <Review contentId={contentId}/>
        )
      } 
      else if(Tab == 3) {
        return (
          <Photo />
        )
      }
      else {
        return (
          <Description />
        )
      }

    } 

    function selectTab(no) {
      setTab(no);
    }

    //상품인 경우 상품정보 코드 생성
    function GoodSection() {
        if(Good) {
            return (
                <div className="goods">
                        <div className="top">
                        <div className="goods-info">
                            <p className="goods-name"> {Good.name}</p>
                            <p className="price">{Good.price.toLocaleString('ko-KR')}<small>원</small></p>
                        </div>
                        <div className="like">
                            <i className="xi-heart-o xi-2x"></i>
                            <p>{Good.like}</p>
                        </div>
                        </div>
                        <div className="bottom">
                        {optionList(Good.option)}
                        {choiceList()}
                        {total()}
                        {/* <div className="total">
                          <p>총 상품금액</p>
                          <h4>123,000<small>원</small></h4>
                        </div> */}
                        <div className="buttons">
                            <div className="cart" onClick={addCart} >장바구니 담기</div>
                            <div className="buy" onClick={goBuy}>바로 구매하기</div>
                        </div>
                        </div>
                    </div>
            )
        } else {
            return <div></div>;
        }
    }

    //상품인 경우 상품정보 코드 생성
    function VideoSection() {
      if(Video.length) {
          return (
            
            <div className="video-outer">
              <div>
                <iframe width="100%" height="100%" src={`${Contents.video[0]}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                </iframe>
              </div>
            </div>
            
          )
      } else {
          return (<div></div>);
      }
    }
    

    // 이미 선택한 옵션인지 확인
    const  checkTwice = (opt) => {
      console.log('Choices: ', Choices);
      console.log('exist? : ', Choices.includes(opt));
      return Choices.includes(opt)
    }

    //이미 선택한 옵션인지 확인하고 아니라면 옵션리스트에 추가함
    const addChoice = (opt) => {

      let optArr = opt.split(',');

      // ChoiceList는 배열형식 담기기 때문인지 중복체크가 안돼서 Choices 추가 사용함 
      if(!checkTwice(opt)){
        //기존 옵션을 가져오기
        let pastChoiceList = ChoiceList;
        let pastChoices = Choices;
        let newTotal = Total;
        //옵션 추가하기
        pastChoiceList.push(optArr);
        pastChoices.push(opt);
        //옵션 state 변경하기
        setChoiceList(pastChoiceList);
        setChoices(pastChoices);
        //합계 금액 변경하기
        newTotal = Number(newTotal) + Number(optArr[1]);
        setTotal(newTotal);
      }
    }

    const goCart = () => {
      if(Total){
        // history.push({
        //   pathname: "/cart",
        //   state: {}
        // });
        alert('로그인 해주세요');
      }else{
        alert('상품을 선택해주세요');
      }
    }

    const addCart = (event) => {
      if(Total){
        // alert('로그인 해주세요');
        event.preventDefault()
        let body = {
          content: contentId,
          img: Contents.img_main,
          good_id: Good._id,
          maker: Good.maker,
          name: Good.name,
          option: ChoiceList
        }
        
        dispatch(intocart(body)).then(response => {
          console.log(response);
          // if(response.cart.anything) {
          if(response.payload) {
            props.history.push('/cart');
          } else {
            alert('카트에 담지 못했습니다');
          }
        })

      }else{
        alert('상품을 선택해주세요');
      }
    }

    const goBuy = () => {
      if(Total){
        alert('로그인 해주세요');
      }else{
        alert('상품을 선택해주세요');
      }
    }

    // 옵션(셀렉트) 선택시 (두개 셀렉트를 A, B로 구분) 
    const handleSelect = (type) => (e) => {
      let opt = e.target.value;

      if(type=="A"){
        setSelectA(opt);
      }else{
        setSelectB(opt);
      }
      
      addChoice(opt);
    }


    // 옵션 코드(<select>) 생성
    function optionList(options) {
      
      return (
        <div>
        {options.map((option, index) => {

          if(!index) 
            return (
              <select key={index} onChange={handleSelect("A")} value={SelectA}>
              {option_item(option)}
              </select>
            )
          return (
            <select key={index} onChange={handleSelect("B")} value={SelectB}>
              {option_item(option)}
              </select>
          )
        })}
        </div>
      )
    }

    // 옵션 상세(<option>) 코드 생성
    function option_item(item) {
      const code = [];
      // code.push(<select></select>);
      
      if(item) {
        for(let i=0; i<item.length; i++) {
          code.push(<option key={i} value={item[i]}>{item[i][0]}</option>)  
        }
      }
      return code;
    }


    function choiceList() {
      return (
        <div>
          {ChoiceList.map((opt, index) => (
                <div className="option">
                   <p className="option-selected">{opt[0]}</p>
                   <div className="option-right">
                   <div className="qty-control">
                       <div className="minus"><i className="xi-minus"></i></div>
                       <div className="qty">1</div>
                       <div className="plus"><i className="xi-plus"></i></div>
                   </div>
                   <div className="fix-width">
                     <p className="price">{Number(opt[1]).toLocaleString('ko-KR')}<small>원</small></p>
                     <a href="#none"><i className="xi-close-circle-o xi-x"></i></a>
                   </div>
                   </div>
                </div>
          ))
          }
        </div>
      )
    }
    
    function total() {
      if(Total){
        return (
          <div className="total">
            <p>총 상품금액</p>
            <h4>{Total.toLocaleString('ko-KR')}<small>원</small></h4>
          </div>
        )
      }else{
        return <div></div>
      }
    }

    return (
        <div>
          <div className="container">

            <section className="detail-content">
            <div className="inner">
                <div className="band">
                <div className="main-image">
                    <img src={`${Server_uri}/${Contents.img_main}`} />
                </div>
                <div className="heading">
                    <h3>{Contents.title}</h3>
                    <p>{Contents.desc}</p>
                </div>

                <GoodSection />
                
                <div className="lnb">
                    <p className="info" onClick={() => selectTab(1)}>상세정보</p>
                    <p className="review" onClick={() => selectTab(2)}>댓글</p>
                    <p className="comment" onClick={() => selectTab(3)}>포토리뷰</p>
                </div>

                <ThreeTabs />
                
                
                </div>
            </div>
            </section>

          </div>
        </div>
    )
}

export default DetailPage
