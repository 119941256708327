import React from 'react';
import Header from '../Common/Header';
import {useLocation} from "react-router";
import { Link } from 'react-router-dom';
import dotenv from 'dotenv'
dotenv.config();


function SearchPage() {

    const location = useLocation();
    const keyword = location.state.keyword;
    const contents = location.state.contents;
    const Server_uri = process.env.REACT_APP_SERVER_URI;

    const contentList = contents.map((content, index) => (
      <div key={index} className="item">
          <div className="image">
          <Link to={`detail/${content._id}`} ><img src={`${Server_uri}/${content.img_main}`} alt='' /></Link>
          </div>
          <div className="caption">
            <h3>{content.title}</h3>
            <p>{content.desc}</p>
          </div>
      </div>
    ));

    return (
        <div>
          <Header />
          <div className="search-band">
            <div className="search-title">
              {/* <div className="search-title"> */}
                <h1>{keyword} 검색결과</h1>
              {/* </div> */}
            </div>
            <section className="contents">
                  <div className="contents-inner">
                    {contentList}
                  </div>
            </section>
          </div>
        </div>
    )
}

export default SearchPage
