import React from 'react';
import DetailPage from './DetailPage';
import Header from '../Common/Header';

function Layout(props) {
    return (
        <div>
            <Header />
            <DetailPage {...props} />
        </div>
    )
}

export default Layout