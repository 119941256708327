export default function (state = {login: false, name: "Anyone"}, action) {console.log('user_reducer in');
    switch (action.type) {
        case "LOGIN" :
            console.log('switch LOGIN in')
            return {...state, login: true, name: action.user.name, email: action.user.email}
            break;

        default :
            return state;
    }
}