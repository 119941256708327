import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { useDispatch } from 'react-redux'
import{ login } from '../../_actions/user_action'
import Header from '../Common/Header'


function Login(props) {

  const [Email, setEmail] = useState("") ;
  const [Password, setPassword] = useState("");
  const [Error, setError] = useState(false) ;
  const [Alert, setAlert] = useState(false) ;
  const dispatch = useDispatch();
  const Kakao_uri = process.env.REACT_APP_KAKAO_AUTH_URI;
  const callbackUrl = process.env.REACT_APP_NAVER_CALLBACK_URI;
  const Server_uri = process.env.REACT_APP_SERVER_URI;
  // const { naver } = window;
  const naverRef = useRef();

  const onEmailHandler = (event) => {

    setEmail(event.currentTarget.value)

  }

  const onPasswordHandler = (event) => {

    setPassword(event.currentTarget.value)

  }

  const onSubmitHandler = (event) => {

    event.preventDefault()

    let body = {
      email: Email,
      password: Password
    }

    dispatch(login(body)).then(response => {
      console.log(response);
      if(response.user.login) {
        props.history.push('/')
      } else {
        alert('로그인에 실패했습니다');
      }
    })
  }

  const kakaoLogin = (event) => {
      alert(Kakao_uri);
      event.preventDefault();

      axios.get(Kakao_uri)
            .then(response => {
                console.log('axios.get 성공!!');
                console.log(response.data);
            })
      let body = {
        // content: contentId,
        // img: Contents.img_main,
        // good_id: Good._id,
        // maker: Good.maker,
        // name: Good.name,
        // option: ChoiceList
      }
      
      // dispatch(intocart(body)).then(response => {
      //   console.log(response);
      //   // if(response.cart.anything) {
      //   if(response.payload) {
      //     props.history.push('/cart')
      //   } else {
      //     alert('카트에 담지 못했습니다');
      //   }
      // })

  }

  const naverLogin = () => {
    const login = new window.naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER,
      callbackUrl,
      isPopup: false,
      loginButton: { color: "green", type: 1, height: 63},
      callbackHandle: true,
    });
    login.init();
  };

  useEffect(() => {
    naverLogin();
  });

  const handleClick = () => {
    naverRef.current.children[0].click();
  }

  return (
    <div>

      <Header />
      <div className="login-outer">
        <div className="content-band">
          <h1>로그인</h1>
          <form  onSubmit={onSubmitHandler}>
            <input className="login-email" type="email" placeholder="아이디 (이메일)" value={Email} onChange={onEmailHandler}/>
            <input className="login-pw" type="password" placeholder="비밀번호" value={Password} onChange={onPasswordHandler}/>
            <button className="login-submit" type="submit" >
              로그인
            </button>
          </form>
          <hr/>
          <div className="kakao-btn">
            <a href={Kakao_uri}>
              <img src="/images/kakao.png" />
            </a>
          </div>
          <div ref={naverRef} id='naverIdLogin' />
          <button onClick={handleClick}  className="naverBtn" >
            <img src={`${Server_uri}/uploads/naver.png`} alt='' />
            <p className="naver-login">네이버 로그인</p>
          </button>
        </div>

      </div>
    </div>
  )
}

export default Login
