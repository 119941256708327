import React from 'react'
import { Link } from 'react-router-dom';

function Footer() {
    const Server_uri = process.env.REACT_APP_SERVER_URI;
    return (
            <div className="footer">
                
              <div className="footer-inner">
                  <hr />
                  <div className="box">
                  <div className="first-box">
                      <div className="logo">
                      <Link to="/"><img src={`${Server_uri}/uploads/logo.png`} alt="logo"/></Link>
                      {/* <Link to="/"><h1 className="logo-text-footer">Artly</h1></Link> */}
                      </div>
                      <div className="slogan"><p>아트플라이는 미술의 즐거움을 전하며<br />
                      더 나은 세상을 만듭니다. </p></div>
                  </div>
                  <div className="second-box">
                      <div className="about">
                      <h5>ABOUT US</h5>
                      <p>회사소개</p>
                      <p>인재채용</p>
                      </div>
                      <div className="contact">
                      <h5>CONTACT</h5>
                      <p>FAQ</p>
                      <p>상담센터</p>
                      </div>
                      <div className="sns">
                      <i className="xi-youtube-play xi-2x"></i>
                      <i className="xi-instagram xi-2x"></i>
                      <i className="xi-naver-square xi-2x"></i>
                      </div>
                  </div>
                  </div>
                  
                  <hr className="last-bar" />
                  <div className="last-box">
                  <p><b><small>(주)</small>아트플라이</b> 본점: 대전광역시 유성구 대학로 99 대전팁스타운 2층 서울사무소: 서울시 강서구 마곡중앙로 161-8 두산더랜드파크 6층 <br />대표자: 이란 | 사업자등록번호: 389-86-01911 통신판매신고번호: 2021-대전유성-1063 고객센터: 02-6254-1100 Email: artfly4u@gmail.com</p>
                  </div>
              </div>
            </div>
    )
}

export default Footer
