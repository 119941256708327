import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Cookies } from 'react-cookie';

function Review(props) {

    const Server_uri = process.env.REACT_APP_SERVER_URI;
    const [Comment, setComment] = useState("");
    const [Reviews, setReviews] = useState([])
    const history = useHistory();
    const cookies = new Cookies();
    const content_id = props.contentId;
    const user = useSelector(state => state.user);
    const token = cookies.get('user_auth');

    useEffect(() => {
      getReview();
    }, [])

    const getReview = () => {
      let payload = { content: content_id };
      axios.post('/api/community/getReview', payload)
      .then(response => {
          if(response.data.success) {
              // console.log('@@@@@@ get reviews finished! @@@@@@');
              // console.log(response.data.reviews);
              setReviews(response.data.reviews);
          } else {
              alert('리뷰를 가져오는데 실패했습니다.')
          }
      })
    }

    const onChangeHandler = (event) => {
      setComment(event.currentTarget.value);
    }

    const onSubmit = (event) => {console.log('@@@ keyword : ' );
      event.preventDefault()

      if(user.login) {

        let variable = {
          comment: Comment,
          token: token,
          content: content_id,
          like: []
        }

        axios.post('/api/community/saveReview', variable)
        .then(response => {
          // console.log('@@@ axios saveReview response finished! @@@');
          if(response.data.success) {
            setComment("");
            getReview();
          }else{
            alert('저장중 오류가 발생했습니다.')
          }
        })

      }else{
        alert('로그인이 필요합니다.')
      }
    }

    const getNewReviews = (like, key) => {
      let newReviews = Reviews;
      newReviews[key].like = like;
      return newReviews;
    }

    // 댓글에 좋아요 표시하기
    const updateLike = (review, like, key) => {

      // review_id, like를 인자로 받음
      // console.log('like 1 : ', like);

      if(user.login) {
        
        // 토큰이 like array에 있는지 확인
        let liked = false;
        let index;
        for (var i=0; i<like.length; i++) {
          if(like[i]= token) {
            liked = true;
            index = i;
          }
        }

        // new like array 선언
        // console.log('like 2 : ', like);
        // let newLike = like;
        // console.log('like 3 : ', like);

        // new reviews array 선언
        // let newReviews = Reviews;
        // 있으면 like array에 해당 id 삭제하고 newLike 세팅
        // 없으면 like array에 해당 id 추가하고 newLike 세팅
        if(liked) {
          like.splice(index, 1);
        } else {
          like.push(token);
        }
        // console.log('like 4 : ', like);

        // 리뷰 id 와 like array를 담는다.
        let payload = {_id: review, like: like};
        
        // like array 변경한 경우 review array 변경  
        // newReviews[key].like = like;
        // console.log('!!! newReviews 1 : ', newReviews[key]);

        // 서버에 리뷰 id 와 like array를 보낸다.
        axios.post('/api/community/likeReview', payload)
        .then(response => {
          if(response.data.success) {
            // console.log('!!! Reviews : ', Reviews[key]); 
            // newReviews 불러오기
            // const neoReviews = getNewReviews(like, key);
            //Reviews 다시 세팅하기 >> 반응하지 않음 추후 개선해서 아래 getReview() 대신해야
            // setReviews(neoReviews);

            //reviews 다시 가져오기
            getReview();

          }else{
            // 에러발생 팝업 
            console.log('!!!! axios.post - /api/community/likeReview error occured !!!!' );
          }
        })

      } else {
        alert('로그인이 필요합니다');
      }
    }

    // 옵션 코드(<select>) 생성
    function optionList() {
      
      return (
        <div>
        {Reviews.map((review, index) => {

          return (
            <div className='review-outer' key={index}>
            <div className='write-info'>
              <div className='profile'>
                <img src={`${Server_uri}/uploads/profile.png`} />
              </div>
              <div className='writeranddate'>
                <p className='writer'>{review.writer.name}</p>
                <p className='date'>{review.createdAt.substring(0,10)}</p>
              </div>
            </div>
            <p className='comment'>{review.comment}</p>
            <div className='icon'>
              <i className="xi-heart-o" onClick={() => updateLike(review._id, review.like, index)}></i> <span>{review.like.length}</span>
              <i className="xi-message-o"></i> <span>0</span>
            </div>
            </div>
          )
        })}
        </div>
      )
    }

    return (
      <>
        {optionList()}

      <div className='review-input'>
        <form onSubmit={onSubmit}>
          <div className="plus-icon" ><i className="xi-plus-circle"></i></div>
          
          <textarea type="text" id="reviewInput" placeholder="리뷰를 입력하세요" value={Comment} onChange={onChangeHandler}/>
          <div className="send-icon" onClick={onSubmit}><i className="xi-send"></i></div>
        </form>
      </div>
      </>
    )
}

export default Review
