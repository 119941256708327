import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import dotenv from 'dotenv'
dotenv.config();

function MainPage() {

    const [Contents, setContents] = useState([]);
    const Server_uri = process.env.REACT_APP_SERVER_URI;

    var settings = {
    //   dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      speed: 800,
      arrows: false,
      fade: true
    };

    useEffect(() => {
        // console.log('@@@@ Server_uri : '+ Server_uri);
        axios.get('/api/content/getContents')
            .then(response => {
                if(response.data.success) {
                    // console.log(response.data);
                    setContents(response.data.contents)
                }else {
                    alert('콘텐츠 가져오기를 실패했습니다')
                }
            })
    }, [])
    
    const contentList = Contents.map((content, index) => (
          <div key={index} className="item">
              <div className="image">
              {/* <Link to={`detail/${content._id}`} ><img src={`http://localhost:5000/${content.img_main}`} /></Link> */}
              <Link to={`/detail/${content._id}`} ><img src={`${Server_uri}/${content.img_main}`} alt='' /></Link>
              </div>
              <div className="caption">
                <Link to={`/detail/${content._id}`} ><h3>{content.title}</h3></Link>
                <Link to={`/detail/${content._id}`} ><p>{content.desc}</p></Link>
              </div>
          </div>
    ));

    return (
        <div>

            <div className="container">
                
                <section className="slide">
                
                    <Slider {...settings}>


                      <div className="slide-outer">
                        <div className="bg-bar no13"> </div>
                        <div className="items">
                        <div className="visual">
                            <img src="images/no2.png" alt=''/>
                        </div>
                        <div className="heading">
                            <h1>미술키트와 컬러링북</h1>
                            <p>코코낫 미술키트와 컬러링북으로<br/>
                            가정에서 미술놀이를 즐길 수 있어요.</p>
                        </div>
                        </div>
                      </div>

                      <div className="slide-outer">
                        <div className="bg-bar no3"> </div>
                        <div className="items">
                        <div className="visual">
                            <img src="images/no3.png" alt='' />
                        </div>
                        <div className="heading">
                            <h1>미술감상</h1>
                            <p>반 고흐, 앙리 마티스, 피카소 등 <br />
                            세계적인 화가의 작품을 감상하세요. 
                            </p>
                        </div>
                        </div>
                      </div>

                      <div className="slide-outer">
                        <div className="bg-bar no12"> </div>
                        <div className="items">
                          <div className="visual">
                              <img src="images/no1.png" alt='' />
                          </div>
                          <div className="heading">
                              <h1>아트살롱</h1>
                              <p>미술이야기를 나누고 그림을 그리며<br />
                              사람들과 즐겁게 교제할 수 있어요.</p>
                          </div>
                        </div>
                      </div>
                      
                    </Slider>
                  
                </section>


                <section className="contents">
                  <div className="contents-inner">
                    {contentList}
                  </div>
                </section>
               

            </div>

        </div>
    )
}

export default MainPage
