import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

function Header() {

    const user = useSelector(state => state.user);
    const Server_uri = process.env.REACT_APP_SERVER_URI;
    const [Search, setSearch] = useState("");
    const [isActive, setActive] = useState(false);
    const history = useHistory();

    const onSearchHandler = (event) => {
      setSearch(event.currentTarget.value);
    }

    const onSubmitHandler = (event) => {console.log('@@@ keyword : ', Search);
      event.preventDefault()

      let variable = {
        keyword: Search
      }

      axios.post('/api/content/searchContents', variable)
      .then(response => {console.log('@@@ axios.post response => ', response.data);
        if(response.data.success) {
          if(response.data.contents.length > 0) {
            history.push({
              pathname: "/search",
              state: {contents: response.data.contents, keyword: Search}
            });
          }else{
            alert('검색결과가 없습니다')
          }
        }else{
          alert('검색중 오류가 발생했습니다.')
        }

      })

    }

    const activateClass = () => {
      setActive(true);
    }

    const removeClass = () => {
      setActive(false);
      setSearch("");
    }
    

    return (
        <div id="header-outer">
            <header>
              <div className="banner-outer">
                  <div className="banner">
                  <span className="hi">미술로 그리는 즐거운 일상, 아트플라이!</span>
                  <div className="navi">
                      <Link to={`login/`}>로그인</Link>
                      <a href="#none">회원가입</a>
                      <a href="#none">마이페이지</a>
                      <a href="#none">고객센터</a>
                  </div>
                  </div>
              </div>
              <div className="nb-outer">
                  <div className="logo">
                  <Link to="/"><img src={`${Server_uri}/uploads/logo.png`} alt="logo"/></Link>
                  {/* <Link to="/"><h1 className="logo-text">Artly</h1></Link> */}
                  </div>
                  <div className="menu">
                    <Link to="/category_diy/0">미술 DIY</Link>
                    <Link to="/category_see/1">미술감상</Link>
                    <Link to="/category_salon/2">아트살롱</Link>
                    <a href="#none">매거진</a>
                    <a href="#none">교육제안</a>
                    <Link to="/intro">아트플라이 소개</Link>
                  </div>

                  <div className="login-btn">
                    {
                      user.login
                      ?
                      // <div><i className="xi-user xi-2x"></i></div>
                      <div className="login-text"><Link to={`/`}>{user.name} 님</Link></div>
                      :
                      <div className="login-text"><Link to={`/login/`}>로그인</Link></div>
                    }
                  </div>
                  <div className={isActive ? "search active" : "search"}>
                    <span className="clear" onClick={removeClass}><i className="xi-close-thin"></i></span>
                    <div className="search-icon" onClick={activateClass}><i className="xi-search"></i></div>
                    <form  onSubmit={onSubmitHandler}>
                      <div className={isActive ? "input-frame active" : "input-frame"} type="submit">
                        <input type="text" id="searchInput" placeholder="검색어를 입력하세요" value={Search} onChange={onSearchHandler}/>
                      </div>
                    </form>
                  </div>
                  
              </div>
            </header>
            
        </div>
    )
}

export default Header
