import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

function MenuBar() {

    const user = useSelector(state => state.user);
    const Server_uri = process.env.REACT_APP_SERVER_URI;
    const [Search, setSearch] = useState("");
    const history = useHistory();

    return (
        <div className="menubar-outer">
          <div className="menubar">
          
            <div className="menu">
              <Link to="/">
                <i className="xi-layout-snb"></i>
                <div className="menu-text">
                  <p>HOME</p>
                </div>
              </Link>
            </div>
            

            <div className="menu">
              <Link to="/category_diy/0">
                <i className="xi-palette"></i>
                <div className="menu-text">
                  <p>DIY</p>
                </div>
              </Link>
            </div>

            <div className="menu">
              <Link to="/category_see/1">
                <i className="xi-caret-up-square"></i>
                <div className="menu-text">
                  <p>SEE</p>
                </div>
              </Link>
            </div>

            <div className="menu">
              <Link to="/category_salon/2">
                <i className="xi-users"></i>
                <div className="menu-text">
                  <p>SALON</p>
                </div>
              </Link>
            </div>

            <div className="menu">
            {
              user.login
              ?
              <Link to="/#none">
                <i className="xi-user"></i>
                <div className="menu-text">
                  <p>MY</p>
                </div>
              </Link>
              :
              <Link to="/login">
                <i className="xi-user"></i>
                <div className="menu-text">
                  <p>MY</p>
                </div>
              </Link>
            }
            </div>
          </div>
        </div>
    )
}

export default MenuBar
