import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import dotenv from 'dotenv'
dotenv.config();

function CategoryPage(props) {

    const type = props.match.params.type;
    const Server_uri = process.env.REACT_APP_SERVER_URI;
    const [Contents, setContents] = useState([]);
    const variable = {type : type};
    const titleImgList = ["diy_title.gif", "see_title.png", "salon_title.png"];
    const titleList = ["미술키트와 컬러링북", "미술감상", "아트살롱"];
    const descList1 = ["코코낫 미술키트와 컬러링북으로", "반 고흐, 앙리 마티스, 피카소 등", "미술이야기를 나누고 그림을 그리며"];
    const descList2 = ["가정에서 미술놀이를 즐길 수 있어요.", "세계적인 화가의 작품을 감상하세요.", "사람들과 즐겁게 교제할 수 있어요."];
    

    useEffect(() => {
      // console.log('@@@@ Server_uri : '+ Server_uri);
      axios.post('/api/content/getContentsOfType', variable)
          .then(response => {
              if(response.data.success) {
                  // console.log(response.data);
                  setContents(response.data.contents)
              }else {
                  alert('콘텐츠 가져오기를 실패했습니다')
              }
          })
    }, [])

    function BgBar() {
      if (type == 0) {
        return (
          <div className="bg-bar no4">bg-bar</div>
        )
      } else if (type == 1) {
        return (
          <div className="bg-bar no3">bg-bar</div>
        )
      } else {
        return (
          <div className="bg-bar no2">bg-bar</div>
        )
      }
      
    }

    const contentList = Contents.map((content, index) => (
      <div key={index} className="item">
          <div className="image">
          {/* <Link to={`detail/${content._id}`} ><img src={`http://localhost:5000/${content.img_main}`} /></Link> */}
          <Link to={`/detail/${content._id}`} ><img src={`${Server_uri}/${content.img_main}`} alt='' /></Link>
          </div>
          <div className="caption">
            <h3>{content.title}</h3>
            <p>{content.desc}</p>
          </div>
      </div>
    ));

    return (
        <div>

            <div className="container">
                
                <section className="slide">
                
                      <div className="slide-outer">
                        <BgBar />
                          <div className="items">
                            <div className="visual">
                                <img src={`${Server_uri}/uploads/${titleImgList[type]}`} alt=''/>
                            </div>
                            <div className="heading">
                                <h1>{titleList[type]}</h1>
                                <p>{descList1[type]}<br/>
                                {descList2[type]}</p>
                            </div>
                          </div>
                      </div>

                </section>

                <section className="contents">
                  <div className="contents-inner">
                    {contentList}
                  </div>
                </section>

                </div>
            
        </div>
    )
}

export default CategoryPage
