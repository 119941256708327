
import React from 'react';
import CenterPage from './MainPage';
import Header from '../Common/Header';

function Layout() {
    return (
        <div>
            <Header />
            <CenterPage />
        </div>
    )
}

export default Layout
