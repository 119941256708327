import React from 'react'
import Header from '../Common/Header'

function IntroPage() {

    const Server_uri = process.env.REACT_APP_SERVER_URI;

    return (
        <div>
          <Header />
          <div className="intro-outer">
            <div className="intro-band">
              <div className="intro-title">
                <h1>
                  미술을 통해 행복한 일상을 꿈꾸는<br/>
                  이곳은 <b>아트플라이</b>입니다.
                </h1>
              </div>
              <img className="intro-main" src={`${Server_uri}/uploads/intro1.png`} alt="logo"/>
              <div className="intro-text">
                <p>
                  아트플라이는 누구나 미술의 즐거움을 경험할 수 있는 <b>온라인 미술콘텐츠 플랫폼</b>입니다. 문화예술을 간편하게 즐길 수 있는 DIY키트, 감상영상 등 <b>흥미로운 미술콘텐츠</b>를 제공합니다. 온라인 문화예술 활동의 파트너, <b>아트플라이</b>를 통해 행복한 일상을 만드세요.       
                </p>
              </div>
              <img className="intro-pic4" src={`${Server_uri}/uploads/intro2.png`} alt="logo"/>
              <div className="intro-text">
                <p className="sub-title">
                  1. 명화 기반의 미술 DIY 키트 
                </p>
                <span>
                  반 고흐, 앙리 마티스 등 최고의 화가의 유명 작품을 자신의 감성으로 완성할 수 있는 미술 DIY 키트를 선보입니다. 그리기, 만들기, 감상하기를 모두 경험할 수 있는 입체그림 키트와 컬러링북을 통해 미술의 세계로 떠나보세요.
                </span>
                <p className="sub-title">
                  2. 언제 어디서나 즐기는 미술감상 
                </p>
                <span>
                  반 고흐, 앙리 마티스 등 최고의 화가는 물론 초현실주의의 거장 살바도르 달리, 추상표현주의 화가 마크 로스코 등의 작품을 온라인으로 감상해보세요.
                </span>
                <p className="sub-title">
                  3. 유쾌한 만남이 있는 미술모임, 아트살롱 
                </p>
                <span>
                  혼자서도 즐겁지만 여러 사람이 함께 모여 미술이야기를 나누고 그림 그리면 즐거움은 더 커진답니다. 현직 미술관 교육담당 선생님이 들려주는 흥미진진한 미술이야기는 모임의 깊이를 더해줄 거에요.
                </span>
              </div>
            </div>

          </div>
        </div>
    )
}

export default IntroPage
