
export function intocart(body) {console.log( 'intocart in')

  const abc = async () => {
    const data = await body; 
    return { type: "CART", payload: data }
  } 
  // 다음과 같이 async await 를 사용하지 않으면 (.then is not a function)라는 에러가 발생함
  // const abc = () => {
  //   const data = body; 
  //   return { type: "CART", payload: data }
  // } 

  return abc();
      
}
  
