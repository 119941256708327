// import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
// import MainPage from './component/MainPage/MainPage';
import MainPage from './component/MainPage/Layout';
import DetailPage from './component/DetailPage/Layout';
// import Header from './component/Common/Header';
import Footer from './component/Common/Footer';
import MenuBar from './component/Common/MenuBar';
import Login from './component/Common/Login';
import CartPage from './component/CartPage/CartPage';
import IntroPage from './component/Common/IntroPage';
import ScrollToTop from './component/Common/ScrollToTop';
import SearchPage from './component/SearchPage/SearchPage';
import CategoryPage_diy from './component/CategoryPage/Layout_diy';
import CategoryPage_see from './component/CategoryPage/Layout_see';
import CategoryPage_salon from './component/CategoryPage/Layout_salon';
import KakaoLoginHandler from './component/Common/KakaoLoginHandler';
import NaverLoginHandler from './component/Common/NaverLoginHandler';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div id="app-outer">
        {/* <Header /> */}
        <Switch>
          <Route exact path="/" component={ MainPage } />
          <Route exact path="/detail/:contentId" component={ DetailPage } />
          <Route exact path="/login" component={ Login } />
          <Route exact path="/search" component={ SearchPage } />
          {/* <Route exact path="/category/:type" component={ CategoryPage } /> */}
          <Route exact path="/category_diy/:type" component={ CategoryPage_diy } />
          <Route exact path="/category_see/:type" component={ CategoryPage_see } />
          <Route exact path="/category_salon/:type" component={ CategoryPage_salon } />
          <Route exact path="/intro" component={ IntroPage } />
          <Route exact path="/cart" component={ CartPage } />
          <Route exact path="/oauth/callback/kakao" component={ KakaoLoginHandler } />
          <Route exact path="/oauth/callback/naver" component={ NaverLoginHandler } />
        </Switch>
        <MenuBar />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
