// export default function (state = {anything: false, items:[]}, action) {console.log('cart_reducer in');
//     switch (action.type) {
//         case "CART" :
//             console.log('switch CART in')
//             return {...state, anything: true, items: [...state.items, action.item] }
//             break;

//         default :
//             return state;
//     }
// }

export default function (state = {anything: false, productList:[]}, action) {console.log('cart_reducer in');
    // var product = action.payload;
    // var

    switch (action.type) {
        case "CART" :
            console.log('switch CART in')
            return {...state, anything: true, productList: [...state.productList, action.payload] }
            break;

        default :
            return state;
    }
}